import {
  module as BaseModule
} from 'modujs';
import Swiper from 'swiper/bundle';

// import 'swiper/css/bundle';

export default class extends BaseModule {
  constructor(m) {
    super(m);
  }

  init() {


    var fieldOfficePage = document.querySelector('.c-map-select');

    if (fieldOfficePage) {

      mapSelector();

    }







    function mapSelector() {

      // breakpoint where swiper will be destroyed
      // and switches to a dual-column layout
      const breakpoint = window.matchMedia('(min-width:1000px)');

      // keep track of swiper instances to destroy later
      let swiperMap;
      let swiper2;

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      const breakpointChecker = function () {

        // if larger viewport and multi-row layout needed
        if (breakpoint.matches === true) {
          var mapIcon = document.querySelectorAll('.swiper-map__nav-item--icon');

          mapIcon.forEach((icon) => {
            icon.addEventListener('click', (event) => {

              var iconValue = event.target.parentElement.parentNode.id;


              switch (iconValue) {
                case 'atlanta':
                  location.href = '/jobs?page_size=10&page_number=1&location=1-38797&radius=0&locationDescription=city&locationName=Atlanta%2C%20GA&sort_by=headline&sort_order=ASC';
                  break;
                case 'bethesda':
                  location.href = '/jobs?page_size=10&page_number=1&location=1-55339&radius=0&locationDescription=city&locationName=Bethesda%2C%20MD&sort_by=headline&sort_order=ASC';
                  break;
                case 'chicago':
                  location.href = '/jobs?page_size=10&page_number=1&location=1-35647&radius=0&locationDescription=city&locationName=Chicago%2C%20IL&sort_by=headline&sort_order=ASC';
                  break;
                case 'columbus':
                  location.href = '/jobs?page_size=10&page_number=1&location=1-49837&radius=0&locationDescription=city&locationName=Columbus%2C%20OH&sort_by=headline&sort_order=ASC';
                  break;
                case 'denver':
                  location.href = '/jobs?page_size=10&page_number=1&location=1-44117&radius=0&locationDescription=city&locationName=Denver%2C%20CO&sort_by=headline&sort_order=ASC';
                  break;
                case 'dallas':
                  location.href = '/jobs?page_size=10&page_number=1&location=1-50503&radius=0&locationDescription=city&locationName=Dallas%2C%20TX&sort_by=headline&sort_order=ASC';
                  break;
                case 'ca':
                  location.href = '/jobs?page_size=10&page_number=1&location=1-32979&radius=0&locationDescription=city&locationName=Long%20Beach%2C%20CA&sort_by=headline&sort_order=ASC';
                  break;
                case 'nashville':
                  location.href = '/jobs?page_size=10&page_number=1&location=1-44434&radius=0&locationDescription=city&locationName=Nashville%2C%20TN&sort_by=headline&sort_order=ASC';
                  break;
                case 'stamford':
                  location.href = '/jobs?page_size=10&page_number=1&location=1-49890&radius=0&locationDescription=city&locationName=Stamford%2C%20CT&sort_by=headline&sort_order=ASC';
                  break;
                case 'wallnut':
                  location.href = '/jobs?page_size=10&page_number=1&location=1-51226&radius=0&locationDescription=city&locationName=Walnut%20Creek%2C%20CA&sort_by=headline&sort_order=ASC';
                  break;

                default:
                  console.log('Not Good');
              }

            });
          }, false);




          // clean up old instances and inline styles when available
          if (swiperMap !== undefined) swiperMap.destroy(true, true);
          if (swiper2 !== undefined) swiper2.destroy(true, true);

          // or/and do nothing
          return;

          // else if a small viewport and single column layout needed
        } else if (breakpoint.matches === false) {

          // fire small viewport version of swiper
          return enableSwiper();

        }

      };

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      const enableSwiper = function () {

        swiperMap = new Swiper(".swiper-map__nav-slider", {
          spaceBetween: 10,
          slidesPerView: 1,
          freeMode: false,
          watchSlidesProgress: true,
          noSwiping: true,   
          allowTouchMove: false,
       
          // scrollbar: {
          
          //   draggable: false,
          // },
          breakpoints: {
            // when window width is >= 320px

            1000: {
              slidesPerView: 10
            },

          }
        });
        swiper2 = new Swiper(".swiper-map__main-slider", {
          spaceBetween: 10,

          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          thumbs: {
            swiper: swiperMap,
          },
        });

      };

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      // keep an eye on viewport size changes
      breakpoint.addListener(breakpointChecker);

      // kickstart
      breakpointChecker();

    }




    const swiper = new Swiper(".c-job-combo-slider__main", {
      autoHeight: true,
      autoplay: true,
      // delay: 3000,
      grabCursor: true,

      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
      },
    });

    const swiperImageInfo = new Swiper(".c-image-info-slider", {
      slidesPerView: 2,
      spaceBetween: 30,
      freeMode: true,
      grabCursor: true,
      enabled: false,
      breakpoints: {
        // when window width is >= 320px

        1000: {
          enabled: true,
          lazy: true,
          // pagination: {
          //   el: ".swiper-pagination",
          //   clickable: true,


          // },
          // navigation: {
          //   nextEl: ".swiper-button-next",
          //   prevEl: ".swiper-button-prev",
          // },
        },

      }
    });


    const blurbSwiper = new Swiper('[data-page="mission-values"] .c-blurb-slider__main', {
      autoHeight: false,
      autoplay: false,
      // delay: 3000,
      grabCursor: true,

      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: false,

        clickable: true,
      },

      breakpoints: {
        // when window width is >= 320px

        1000: {
          slidesPerView: 2,
          enabled: true,
          spaceBetween: 30,
          lazy: true,

        },
      }

    });


    const uniBlurbSwiper = new Swiper('[data-page="university"] .c-blurb-slider__main', {
      autoHeight: true,
      autoplay: false,
      // delay: 3000,
      grabCursor: true,
      spaceBetween: 30,

      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: false,

        clickable: true,
      },

      breakpoints: {
        // when window width is >= 320px

        1000: {
          slidesPerView: 1,
          enabled: true,
          centeredSlides: true,
          spaceBetween: 30,
          lazy: true,

          loop: true

        },
      }

    });




    toggleItem(document.querySelectorAll('.swiper-control-options > button'));

    var benfitsPage = document.querySelector('.c-benefits');

    if (benfitsPage) {

      mobileBenefits();

    }



    function toggleItem(elem) {
      for (var i = 0; i < elem.length; i++) {
        elem[i].addEventListener("click", function (e) {
          var current = this;
          for (var i = 0; i < elem.length; i++) {
            if (current != elem[i]) {
              elem[i].classList.remove('active');
            } else if (current.classList.contains('active') === true) {
              current.classList.remove('active');
              swiper.autoplay.start();
            } else {
              current.classList.add('active');
              swiper.autoplay.stop();
            }
          }
          e.preventDefault();
        });
      };
    }


    function mobileBenefits() {

      // breakpoint where swiper will be destroyed
      // and switches to a dual-column layout
      const breakpoint = window.matchMedia('(min-width:1000px)');

      // keep track of swiper instances to destroy later
      let mySwiper;

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      const breakpointChecker = function () {

        // if larger viewport and multi-row layout needed
        if (breakpoint.matches === true) {

          // clean up old instances and inline styles when available
          if (mySwiper !== undefined) mySwiper.destroy(true, true);

          // or/and do nothing
          return;

          // else if a small viewport and single column layout needed
        } else if (breakpoint.matches === false) {

          // fire small viewport version of swiper
          return enableSwiper();

        }

      };

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      const enableSwiper = function () {

        mySwiper = new Swiper('.c-benefits-list-scroll__nav', {

          // loop: true,

          slidesPerView: 'auto',

          centeredSlides: true,

          a11y: true,
          keyboardControl: true,
          grabCursor: true,

          pagination: {
            el: ".c-benefits-list-scroll__nav-pagination",
            clickable: true,
          },

        });

      };

      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////

      // keep an eye on viewport size changes
      breakpoint.addListener(breakpointChecker);

      // kickstart
      breakpointChecker();

    }









  }


}