import {
  module as BaseModule
} from 'modujs';
import {
  Fancybox
} from "@fancyapps/ui";


export default class extends BaseModule {
  constructor(m) {
    super(m);
  }
  

  init() {

    const mediaQuery = window.matchMedia('(max-width: 1000px)');


    // if (mediaQuery.matches) {
    //   Fancybox.destroy();
    // }
  }
}