import {
    module as BaseModule
} from 'modujs';

export default class extends BaseModule {
    constructor(m) {
        super(m);
    }

    init() {
        const innerWidth = window.innerWidth;
        const innerHeight = window.innerHeight;
        const canvas = document.querySelector('canvas');
        canvas.width = innerWidth;
        canvas.height = innerHeight;

        // context
        var c = canvas.getContext('2d');

        // circle object
        function Circle(x, y, dx, dy, radius) {
            this.x = x;
            this.y = y;
            this.dx = dx;
            this.dy = dy;
            this.radius = radius;

            this.draw = function () {
                c.beginPath();
                c.arc(this.x, this.y, this.radius, 0, 2 * Math.PI, false);
                c.strokeStyle = '#FFBC0D';
                c.stroke();
            };



            this.update = function () {
                if ((this.x + this.radius) >= innerWidth || (this.x - this.radius) < 0) {
                    this.dx = -this.dx;
                }

                if ((this.y + this.radius) >= innerHeight || (this.y - this.radius) < 0) {
                    this.dy = -this.dy;
                }

                this.x += this.dx;
                this.y += this.dy;


                this.draw();
            };
        }

        // make circle
        const circles = [];
        const amount = 10;
        for (var i = 0; i < amount; i++) {
            const radius = Math.random() * 50;
            const x = Math.random() * (innerWidth - radius * 2) + radius;
            const y = Math.random() * (innerHeight - radius * 2) + radius;
            const dx = (Math.random() - 0.5);
            const dy = (Math.random() - 0.5);
            const circle = new Circle(x, y, dx, dy, radius);
            circles.push(circle);
        }

        // animate loop 
        function animate() {
            requestAnimationFrame(animate);
            c.clearRect(0, 0, innerWidth, innerHeight);
            circles.map((circle) => {
                circle.update();
            });
        }

        animate();
    }
}