import {
    module as BaseModule
} from 'modujs';
import {
    lazyLoadImage
} from '../utils/image';
import LocomotiveScroll from 'locomotive-scroll';
import {
    html
} from "../utils/environment";
// import { gsap } from "gsap";



export default class extends BaseModule {
    constructor(m) {
        super(m);
        // var firefoxScroll = document.querySelector('.c-image-info-slider__wraper');  
        // var isFirefox = document.querySelector('.is-firefox');  
        // if(firefoxScroll && isFirefox) {
        //     delete firefoxScroll.dataset.scroll;

        // }


    }


    init() {



        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: false
            // reloadOnContextChange: true
            // lerp: 0.1,
            // multiplier: 1.2,
        });

        // this.anchorScroll();


        // var executed = false;
        // var updateScroll = ( () => {
        //     executed = true;
        //     this.scroll.update();
        // });






        this.scroll.on('call', (func, way, obj, id) => {
            // Using modularJS
            this.call(func[0], {
                way,
                obj
            }, func[1], func[2]);
        });





        this.scroll.on('scroll', ({
            scroll,
            limit,
            currentElements
        }) => {

            const deviceWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
            const scrollBottom = window.scrollY + window.innerHeight;
            var scrollAmount;

            deviceWidth <= 1000 ? scrollAmount = 50 : scrollAmount = 190;

            window.scrollY > scrollAmount ?
                html.classList.add("has-scrolled", "has-nav") :
                html.classList.contains("has-scrolled", "has-nav") &&
                html.classList.remove("has-scrolled", "has-nav");

            scrollBottom > document.body.clientHeight - 100 ?
                html.classList.add("has-scrolled-bottom") :
                html.classList.contains("has-scrolled-bottom") &&
                html.classList.remove("has-scrolled-bottom");

        });
    }

    /**
     * Lazy load the related image.
     *
     * @see ../utils/image.js
     *
     * It is recommended to wrap your `<img>` into an element with the
     * CSS class name `.c-lazy`. The CSS class name modifier `.-lazy-loaded`
     * will be applied on both the image and the parent wrapper.
     *
     * ```html
     * <div class="c-lazy o-ratio u-4:3">
     *     <img data-scroll data-scroll-call="lazyLoad, Scroll, main" data-src="http://picsum.photos/640/480?v=1" alt="" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" />
     * </div>
     * ```
     *
     * @param {LocomotiveScroll} args - The Locomotive Scroll instance.
     */
    lazyLoad(args) {
        // if(window.location.hash === "#diversity") {
        //     let src = this.getData('custom', args.obj.el);
        //     args.obj.el.src = src;
        // } else {

        // }

        lazyLoadImage(args.obj.el, null, () => {});


    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el);
        if (src.length) {
            if (args.obj.el.tagName === 'IMG') {
                args.obj.el.src = src;
            } else {
                args.obj.el.style.backgroundImage = `url('${src}')`;
            }
            this.setData('lazy', '', args.obj.el);
        }
    }

    anchorScroll() {

        const isHomeScreen = document.querySelector('#home');
        const submenuLink = document.querySelectorAll('.c-header-navigation__item-submenu a, .global-menu__wrap a');
        if (isHomeScreen) {
            for (var i = 0; i < submenuLink.length; i++) {
                submenuLink[i].addEventListener('click', (event) => {
                    var scrollLinks = event.target.getAttribute('id');

                    switch (scrollLinks) {
                        case 'benefits-perks-linker':
                        case 'benefits-perks-linker-mobile':
                            this.scroll.scrollTo(document.querySelector('#benefits-perks-scrollto'));
                            break;
                        case 'mission-vision-linker':
                        case 'mission-vision-linker-mobile':
                            this.scroll.scrollTo(document.querySelector('#mission-vision-scrollto'));
                            break;
                        case 'diversity-inclusion-linker':
                        case 'diversity-inclusion-linker-mobile':
                            this.scroll.scrollTo(document.querySelector('#diversity-inclusion-scrollto'));
                            break;
                        case 'team-linker':
                        case 'team-linker-mobile':
                            this.scroll.scrollTo(document.querySelector('#team-scrollto'));
                            break;

                        case 'maccas-linker':
                        case 'maccas-linker-mobile':
                            this.scroll.scrollTo(document.querySelector('#maccas-scrollto'));
                            break;


                        case 'promise-linker':
                        case 'promise-linker-mobile':
                            this.scroll.scrollTo(document.querySelector('#promise-scrollto'));
                            break;


                        case 'benefits-linker':
                        case 'benefits-linker-mobile':
                            this.scroll.scrollTo(document.querySelector('#benefits-scrollto'));
                            break;

                        case 'people-linker':
                        case 'people-linker-mobile':
                            this.scroll.scrollTo(document.querySelector('#people-scrollto'));
                            break;



                        default:
                            console.log(`Sorry`);
                    }

                });
            }

        }


    }






    destroy() {
        this.scroll.destroy();
    }
}