import { module as BaseModule } from "modujs";

export default class extends BaseModule {
  constructor(m) {
    super(m);
  }

  init() {
    var h = document.documentElement,
      b = document.body,
      st = "scrollTop",
      sh = "scrollHeight",
      progress = document.querySelector(".progress"),
      scroll;

    document.addEventListener("scroll", function () {
      scroll = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
      progress.style.setProperty("--scroll", scroll + "%");
    });
  }
}
