import {
    module as BaseModule
} from 'modujs';

export default class extends BaseModule {
    constructor(m) {
        super(m);
    }

    init() {
        let tabBtns = document.querySelectorAll(".c-benefits-list-scroll__item");
        let tabContents = document.querySelectorAll(".c-benefits-list-scroll__item-wall");

        if (tabBtns) {


            for (let i = 0; i < tabBtns.length; i++) {
                tabBtns[i].onclick = function (e) {
                    let id = tabBtns[i].dataset.target;

                    if (id) {
                        // remove active from other buttons

                        tabBtns.forEach((btn) => {
                         
                            btn.classList.remove('active');
                            tabBtns[i].classList.add('active');                           
                        });
                        // hide other tabContents
                        tabContents.forEach((content) => {
                            
                            content.classList.remove("active");
                        });

                        const currentContent = document.getElementById(id);
                        currentContent.classList.add("active");
                    }
                    
                };
            }



        }


    }
}