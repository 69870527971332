// import "normalize.css";
import "../styles/main.scss";

import modular from 'modujs';
import * as modules from './modules';
import { html } from './utils/environment';

const { detect } = require('detect-browser');
const browser = detect();


const app = new modular({
    modules: modules
});


class Zxni {
    constructor() {
      // footer();
      html.classList.add("is-loading", "js");
      if (browser) {
        html.classList.add(`is-${browser.name}`);
        if(browser.name != "chrome") {
          document.querySelectorAll('.svg-gooey__button').forEach(function (elem) {
            elem.remove();
        });
        
          
        }
      }

      this.init();
      // window.addEventListener("load", () => {
        
      // });
    }
  
    init() {
      html.classList.add("is-loaded", "is-ready");
      html.classList.remove("is-loading");
      app.init(app);
    }
  }

  function footer() {
    const oscroll = document.querySelector('.o-scroll');
    const footer = document.querySelector('.c-footer');
    if(oscroll) {
      oscroll.appendChild(footer);
    }
  }
  
  // IIFE for loading the application
  // ==========================================================================
  (function () {
    // if(window.navigator.userAgent.includes('; wv')) { return; }
    // if (window.navigator.userAgent.includes('; wv)') { return }
    new Zxni();
  })();
  