import { module as BaseModule } from 'modujs';

export default class extends BaseModule {
    constructor(m) {
        super(m);
    }

    init() {
        function setAttributes(elements, attributeName, attributeValue) {
            elements.forEach((element) => {
                element.setAttribute(attributeName, attributeValue);
            });
        }

        function createPlugin(el, options) {
            return {
                el,
                settings: Object.assign({}, defaults, options || {}),
                _defaults: defaults,
                _name: pluginName,

                init() {
                    this.initWrap();
                    this.initLink();

                    if (this.settings.addClass) {
                        this.el.classList.add(this.settings.addClass);
                    }

                    if (typeof this.settings.onWindowResize === 'function') {
                        window.addEventListener('resize', this.settings.onWindowResize.bind(this));
                    }

                    this.updateState();
                },

                initWrap() {
                    this.$wrap = document.createElement('div');
                    this.$wrap.className = this.settings.wrapClass;

                    while (this.el.firstChild) {
                        this.$wrap.appendChild(this.el.firstChild);
                    }

                    this.el.appendChild(this.$wrap);
                    this.wrapTextNodes();
                },

                wrapTextNodes() {
                    const childNodes = this.$wrap.childNodes;

                    for (let i = 0; i < childNodes.length; i++) {
                        const node = childNodes[i];

                        if (node.nodeType === 3 && node.textContent.trim() !== '') {
                            const p = document.createElement('p');
                            p.appendChild(node.cloneNode(true));
                            this.$wrap.replaceChild(p, node);
                        }
                    }
                },

                initLink() {
                    this.$linkText = document.createElement('span');
                    this.$linkText.textContent = this.settings.linkText;

                    this.$link = document.createElement('a');
                    this.$link.href = '#';
                    this.$link.className = this.settings.linkClass;

                    if (this.settings.addLinkClass) {
                        this.$link.classList.add(this.settings.addLinkClass);
                    }

                    this.$link.appendChild(this.$linkText);

                    if (!this.settings.hideIcon) {
                        this.$icon = document.createElement('i');
                        this.$icon.className = this.settings.iconClass;
                        this.$link.appendChild(this.$icon);
                    }

                    this.$link.addEventListener('click', (e) => {
                        e.preventDefault();
                        this.toggleState();
                    });

                    this.el.appendChild(this.$link);
                },

                calculateHeightClose: function () {
                  const that = this;
                  const lines = [];
                  let i = 0;
                
                  const childElements = this.$wrap.children;
                
                  Array.from(childElements).forEach(function(child) {
                    const computedStyle = window.getComputedStyle(child);
                    const mTop = parseInt(computedStyle.marginTop, 10);
                    const pTop = parseInt(computedStyle.paddingTop, 10);
                    const top = child.offsetTop; // Relative to the offset parent
                    const height = child.offsetHeight;
                    const countLine = Math.ceil(height / that.settings.lineHeight);
                
                    for (i = 0; i < countLine; i++) {
                      lines.push({
                        top: top + mTop + pTop + that.settings.lineHeight * i,
                        height: that.settings.lineHeight
                      });
                    }
                  });
                
                  const showAllLines = lines.length <= this.settings.size;
                
                  if (showAllLines) {
                    return this.$wrap.scrollHeight;
                  }
                
                  return lines[this.settings.size - 1].top + lines[this.settings.size - 1].height;
                },
                

                recalculateHeight() {
                    this.heightClosed = this.calculateHeightClose();
                    this.heightOpen = this.$wrap.scrollHeight;
                },

                updateState() {
                    this.recalculateHeight();

                    const showAllLines = this.heightClosed === this.heightOpen;

                    this.$link.classList.toggle(this.settings.linkClassHidden, showAllLines);
                    this.$wrap.classList.toggle(this.settings.wrapClassOpen, showAllLines);

                    if (showAllLines) {
                        this.$wrap.style.height = 'auto';
                    } else {
                        this.$wrap.style.height = this.heightClosed + 'px';
                    }
                },

                toggleState() {
                    this.$link.classList.toggle(this.settings.linkClassOpen);
                    this.$wrap.classList.toggle(this.settings.wrapClassOpen);

                    if (!this.settings.hideIcon) {
                        this.$icon.classList.toggle(this.settings.iconClassOpen);
                    }

                    if (this.$link.classList.contains(this.settings.linkClassOpen)) {
                        this.$wrap.style.height = this.heightOpen + 'px';
                        this.$linkText.textContent = this.settings.linkTextOpen;
                    } else {
                        this.$wrap.style.height = this.heightClosed + 'px';
                        this.$linkText.textContent = this.settings.linkText;
                    }
                },
            };
        }

        const pluginName = 'readmore';
        const defaults = {
            lineHeight: 24,
            size: 5,
            addClass: 'readmore',
            wrapClass: 'readmore__wrap',
            wrapClassOpen: 'readmore__wrap--open',
            linkClassHidden: 'readmore__link--hidden',
            addLinkClass: null,
            linkClass: 'readmore__link',
            linkClassOpen: 'readmore__link--open',
            linkText: 'See More',
            linkTextOpen: 'See Less',
            hideIcon: false,
            iconClass: 'readmore__icon',
            iconClassOpen: 'readmore__icon--open',
            onWindowResize: function () {
                this.updateState();
            },
        };

        const elements = document.querySelectorAll('div.testje');
        setAttributes(elements, 'data-component', 'readmore');

        function responsiveParams() {
            const MAX_MOBILE_WIDTH = 640;
            const MAX_TABLET_WIDTH = 1024;
            const windowWidth = window.innerWidth;

            const params = {
                small: {
                    size: 9,
                    lineHeight: 24,
                },
                medium: {
                    size: 9,
                    lineHeight: 28,
                },
                large: {
                    size: 10,
                    lineHeight: 24,
                },
            };

            if (windowWidth <= MAX_MOBILE_WIDTH) {
                return params.small;
            }

            if (windowWidth > MAX_MOBILE_WIDTH && windowWidth <= MAX_TABLET_WIDTH) {
                return params.medium;
            }

            return params.large;
        }

        const params = responsiveParams();
        params.onWindowResize = function () {
            Object.assign(this.settings, responsiveParams());
            this.updateState();
        };
        params.addLinkClass = 'research__link';

        const readmoreElements = document.querySelectorAll('[data-component="readmore"]');
        readmoreElements.forEach((el) => {
            if (!el.classList.contains('readmore-initialized')) {
                el.classList.add('readmore-initialized');
                const pluginInstance = createPlugin(el, params);
                pluginInstance.init();
            }
        });
    }
}
